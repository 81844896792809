import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/Login";
import PasswordReset from "../pages/PasswordReset";
import { PreventLogin } from "./PreventLogin";
import { ProtectedRoutes } from "./ProtectedRoutes";
import { lazy, Suspense } from "react";
import { Loading } from "../components/Loading";

const Home = lazy(() => import("../pages/Home"));
const Registrar = lazy(() => import("../pages/Registrar/Registrar"));
const Propietarios = lazy(() => import("../pages/Propietarios"));
const Razas = lazy(() => import("../pages/Razas"));
const Aplicables = lazy(() => import("../pages/Aplicables"));
const Ubicaciones = lazy(() => import("../pages/Ubicaciones"));
const AnimalsHome = lazy(() => import("../pages/animals/AnimalsHome"));
const Animal = lazy(() => import("../pages/animals/Animal"));
const Edit = lazy(() => import("../pages/animals/Edit"));
const Sell = lazy(() => import("../pages/animals/Sell"));
const Dead = lazy(() => import("../pages/animals/Dead"));
const Venta = lazy(() => import("../pages/Venta"));
const Gastos = lazy(() => import("../pages/Gastos"));
const Resumen = lazy(() => import("../pages/Resumen"));
const NavCR = lazy(() => import("../components/NavCR"));

const Router = () => {

    return (
        <Suspense fallback={<Loading />}>
            <BrowserRouter>
                <Routes>
                    <Route element={<PreventLogin />}>
                        <Route path="ingresar" element={<Login />} />
                        <Route path="recuperar" element={<PasswordReset />} />
                    </Route>

                    <Route element={<ProtectedRoutes />}>

                        <Route element={<NavCR />}>
                            <Route path="/" element={<Home />} />
                            <Route path="registrar" element={<Registrar />} />
                            <Route path="propietarios" element={<Propietarios />} />
                            <Route path="razas" element={<Razas />} />
                            <Route path="aplicables" element={<Aplicables />} />
                            <Route path="ubicaciones" element={<Ubicaciones />} />

                            <Route path="animales" element={<AnimalsHome />} />
                            <Route path="animales/:animal" element={<Animal />} />
                            <Route path="animales/:animal/editar" element={<Edit />} />
                            <Route path="animales/:animal/vender" element={<Sell />} />
                            <Route path="animales/:animal/perdida" element={<Dead />} />

                            <Route path="venta" element={<Venta />} />
                            <Route path="gastos" element={<Gastos />} />
                            <Route path="resumen" element={<Resumen />} />
                        </Route>

                    </Route>

                </Routes>
            </BrowserRouter>
        </Suspense>
    )
}

export default Router;