import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/Auth.context';

export const ProtectedRoutes = () => {

    const { token } = useAuth();

    if (token === null) {
        return <Navigate to="/ingresar" />
    } else if (token) {
        return <Outlet />
    } return null
}