import { InputApp, InputAppProps } from "appdland-ui"


interface InputCRInt extends Omit<InputAppProps, "type"> {
    type?: 'text' | 'number' | 'password';
}

const InputCR = ({ type = 'text', ...props }: InputCRInt) => {

    return (
        <InputApp
            type={type}
            placeholder={props.placeholder}
            {...props}
            style={{
                type: 'box',
                background: "transparent",
                color: "#CBDED6",
                blurColor: '#378064',
                placeholderColor: '#B3E0CB',
                blurPlaceholderColor: '#378064',
                textAlign: "center",
                backgroundColor: "#001F13",
                borderRadius: 20,
                placholderTop: true,
                ...props.style
            }}
            errorBelowInput
        />
    )
}
export default InputCR;