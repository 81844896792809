import ButtonCR from "../components/ButtonCR";
import InputCR from "../components/InputCR";
import { useNavigate } from "react-router-dom";
import useAuthService from "../services/useAuth.service";
import { useAuth } from "../context/Auth.context";
import { FormApp, FormAppProvider, useFormApp } from "appdland-ui";
import TitleCR from "../components/TitleCR";
import './styles/login.css'

const Login = () => (
    <FormAppProvider>
        <LoginContent />
    </FormAppProvider>
)

const LoginContent = () => {

    const { logSesion } = useAuthService();
    const { setToken } = useAuth();

    const { register, validateForm, formValues } = useFormApp();


    const login = () => {
        logSesion(formValues)
            .then(tokenRec => setToken(tokenRec));
    }

    const navigate = useNavigate();

    return (
        <div className="container-box">
            <h1>GanadApp</h1>
            <FormApp
                onSubmit={login}
                validateForm={validateForm}
                className="login-form-container"
            >
                <TitleCR>Iniciar Sesión</TitleCR>
                <InputCR
                    {...register("user", { min: 4, max: 60 })}
                    placeholder="Usuario"
                />
                <InputCR
                    {...register("password", { min: 6, max: 30 })}
                    type="password"
                    placeholder="Contraseña"
                />
                <ButtonCR
                    title="Entrar"
                />
                <ButtonCR
                    title="Olvidé mi contraseña"
                    style={{
                        type: "light",
                        textColor: "#B3E0CB"
                    }}
                    onClick={() => navigate('/recuperar')}
                />
            </FormApp>
            <p style={{ opacity: "0.4" }}>&copy;By AppDland</p>
        </div>

    )
};
export default Login;