import { useEffect } from "react";
import { AuthProvider } from "./context/Auth.context";
import Router from "./router/Router";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingProvider } from 'appdland-ui';

document.querySelectorAll('iframe').forEach(iframe => console.log(iframe.src));

const App = () => {

    useEffect(() => {
        const intervalId = setInterval(() => {
            const iframes = document.querySelectorAll('iframe');
            if (iframes.length > 0) {
                iframes.forEach((iframe) => console.log('Found iframe:', iframe.src));
            }
        }, 1000); // Verifica cada segundo

        // Limpia el intervalo al desmontar el componente
        return () => clearInterval(intervalId);
    }, []);

    return (
        <AuthProvider>
            <LoadingProvider background="dark" loadingColor="#B3E0CB">
                <ToastContainer
                    position="top-right"
                    limit={3}
                    newestOnTop
                    draggable
                    hideProgressBar
                    closeOnClick
                    autoClose={3000}
                />
                <Router />
            </LoadingProvider>
        </AuthProvider>
    );
}

export default App;
