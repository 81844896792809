import axios from "axios";
// import { useLoading } from "../context/LoadingContext";
import { toast } from "react-toastify";
import { useLoading } from "appdland-ui";
import { useAuth } from "../context/Auth.context";
import useHeader from "./useHeader";

const useAuthService = () => {

    const { setLoading } = useLoading();
    const { setToken } = useAuth();
    const headers = useHeader();


    const logSesion = async (dataAuth: any) => {
        try {
            setLoading(true)
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/login`, dataAuth);
            console.log(data);
            return data.token as string
        } catch (error: any) {
            console.log(error)
            toast(error.response.data.message, { type: "error" })
        } finally {
            setLoading(false)
        }

    };

    const forgotPass = async (email: string) => {
        try {
            setLoading(true)
            const { data } = await axios.post("http://192.168.0.190:5050/forgot-pass", { user_email: email });
            return data.user as string
        } catch (error) {
            toast('', { type: "error" })
        } finally {
            setLoading(false)
        }
    };

    const forgotPassCheck = async (user: string, code: string) => {
        try {
            setLoading(true)
            const { data } = await axios.post("http://192.168.0.190:5050/forgot-pass-check-code", { user, code });
            return data.token as string
        } catch (error) {
            toast('', { type: "error" })
        } finally {
            setLoading(false)
        }

    };

    const forgotPassChange = async (token: string, password: string) => {
        try {
            setLoading(true)
            await axios.post("http://192.168.0.190:5050/forgot-pass-change", { token, password });
        } catch (error) {
            toast('', { type: "error" });
        } finally {
            setLoading(false);
        }
    };

    const logOut = () => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, { headers })
            .then(() => {
                setToken(null);
                sessionStorage.clear();
                localStorage.clear();
            })
            .catch(() => {
                toast("Error al cerrar sesión", { type: "error" })
            })
            .finally(() => setLoading(false));
    }
    return {
        logSesion,
        forgotPass,
        forgotPassCheck,
        forgotPassChange,
        logOut
    }

}

export default useAuthService;